const workItems = [
    {
      company: 'E4 Technology Inc.',
      date: '2018-Present',
      position: 'Software Engineer',
      location: 'Huntsville, AL',
      description: `I currently oversee a team of developers working on multiple projects for customers in a number of industries. My team is currently implementing solutions using realtime
        communication to improve outcomes for patients in emergency situations.`,
      bullets: [
        'Manage a team of four developers across three projects to implement and maintain requirements driven web and mobile applications for various customers',
        'Design and implement web applications with real-time communication capabilities to provide casualty data up to twenty minutes in advance',
        'Directed on-site client training to instruct technical and non-technical users about program capabilities '
      ]
    },
    {
      company: 'Anvil, LLC.',
      date: '2016-Present',
      position: 'Lead Software Developer',
      location: 'Huntsville, AL',
      description: `Working at Anvil has provided a wide range of experiences, including IT consulting and solving complex data driven problems. 
      We have worked with a wide range of clients on problems ranging from sales and medical dashboards to payment processing.`,
      bullets: [
        'Lead development on numerous projects to define project requirements, create product development plans, and develop business opportunities using Microsoft Azure (cloud based) services',
        'Design and implement web applications, web services (WCF) and database services that have recovered over $2M from money laundering activities',
        'Designed, implemented, and published two native mobile applications for Android and iOS with thousands of users',
        'Implemented and maintained eight MVC 6 web applications using Angular, React, .NET Core and SQL Server'
      ]
    },
    {
      company: 'PeopleTec, Inc.',
      date: '2015-2017',
      position: 'Web Application Developer',
      location: 'Huntsville, AL',
      description: `Through this position I have been able to participate in all aspects of a large development effort including requirements analysis, creation of design docuentation, iteration planning, development, testing, and delivery. 
      I developed a solid understanding of the ASP.NET MVC 5 framework for server-side development and Angular for client-side functions.`,
      bullets: [
        'Performed requirements analysis, created and maintained design documentation, and tracked work items using Team Foundation Services for a customer',
        'Implemented, tested, and delivered MVC 5 web applications using Angular and SQL Server',
        'Deployed TFS build agent to incorporate continuous deployment into team workflow'
      ]
    },
    {
      company: 'Metova, Inc.',
      date: '2014-2015',
      position: 'Android Developer',
      location: 'Franklin, TN',
      description: `As my first position out of college, I was able to discover a massive amount about the development process in a very short time. 
      I was able to take advantage of various Atlassian products including Jira and Confluence to track work items and log important project details.`,
      bullets: [
        'Responsible for all aspects of an Android development project including client relation management, implementation, testing, and deployment to four countries with over 1M users',
        'Tracked work items and project status through Jira and logged numerous project parameters and development practices with Confluence'
      ]
    },
    {
      company: 'United States Army',
      date: '2010-2012',
      position: 'Engineering Intern',
      location: 'Huntsville, AL',
      description: `This internship provided a solid foundation in project management fundamentals, primarily with a software focus. I was able to participate in requirements definition, risk assessment and management,
      and long term planning of milestones.`,
      bullets: [
        'Translated user and customer needs into programmatic requirements for research, development, and production of processes and services'
      ]
    },
  ];

  export default workItems;