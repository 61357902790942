import React from 'react';
import workItems from './WorkItems';
import { PieChart } from 'react-minimal-pie-chart';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faCopyright, faCheck } from '@fortawesome/free-solid-svg-icons';

import './App.scss';

const languages = [
  '.NET Core',
  'ASP.NET MVC',
  'React',
  'TypeScript',
  'Angular',
  'SQL',
  'Git',
  'C++',
  'Sass',
  'Java',
  'WPF',
  'TFS',
  'Adobe Creative Suite'
]

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="top-bar container-fluid">
            <div className="actions">
                
            </div>
            <ul className="social list-inline">
                <li><a href="https://www.linkedin.com/in/chandler-reynolds-460a9871"><FontAwesomeIcon icon={faLinkedin} /></a></li>
            </ul>
        </div>

        <div className="intro">
            <div className="container text-center">
                <img className="profile-image" src="img/chandler-avatar-2.png" alt="" />
                <h1 className="name">Chandler Reynolds</h1>
                <div className="title">Web &amp; Mobile Developer</div>
                <div className="profile">
                    <p>
                      Hi I'm Chandler, a <em>Software Engineer</em> with over 7 years of experience, currently leading development on multiple projects for customers across many industries.
                    </p>
                </div>
            </div>
        </div>

        <div className="contact-info">
          <div className="container text-center">
              <ul className="list-inline">
                  <li className="email"><i className="fa fa-envelope"></i><a href="mailto:hello@chandlerreynolds.com">hello@chandlerreynolds.com</a></li>
                  <li className="email"><a href="https://www.linkedin.com/in/chandler-reynolds-460a9871">linkedin</a></li>
              </ul>
          </div>
        </div>

        <div className="page-nav-space-holder hidden-xs">
            <div id="page-nav-wrapper" className="page-nav-wrapper text-center">
                <div className="container">
                    <ul id="page-nav" className="nav page-nav list-inline">
                        <li><a className="scrollto" href="#experiences-section">Experience</a></li>
                        <li><a className="scrollto" href="#education-section">Education</a></li>
                        <li><a className="scrollto" href="#skills-section">Skills</a></li>
                        <li><a className="scrollto" href="#contact-section">Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </header>


      <div className="wrapper container">
          <section id="experiences-section" className="experiences-section section">
            <h2 className="section-title">Experience</h2>
            <div className="timeline">
              {workItems.map((item, index) => 
                <div className="item">
                  <div className="work-place">
                    <h3 className="place">{item.company}</h3>
                    <div className="location">{item.location}</div>
                  </div>
                  <div className="job-meta">
                    <div className="title">{item.position}</div>
                    <div className="time">{item.date}</div>
                  </div>
                  <div className="job-desc">
                    <p>
                      {item.description}
                    </p>
                    <ul>
                      {item.bullets.map(bullet => 
                        <li>{bullet}</li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </section>

        <section id="education-section" className="education-section section">
            <h2 className="section-title">Education</h2>
            <div className="row">
                <div className="item col-xs-12 col-sm-4">
                    <div className="item-inner">
                        <img src="img/auburn-logo.svg" className="img-fluid auburn-logo" alt="Auburn University" />
                    </div>
                </div>
                <div className="item col-xs-12 col-sm-4">
                    <div className="item-inner">
                        <h3 className="degree">BA Software Engineering</h3>
                        <div className="education-body">
                            Auburn University
                        </div>
                        <div className="time">2010 - 2014</div>
                        <div className="desc">
                            I chose Auburn University due to the excellent Software Engineering program. I served as the Media Director of the Formula SAE team, in addition to contributing to electronics and telemetry.
                        </div>
                    </div>
                </div>
                <div className="item col-xs-12 col-sm-4">
                    <div className="item-inner">
                        <img src="img/aufsae.jpg" className="img-fluid img-rounded" alt="Auburn University Formula SAE" />
                    </div>
                </div>
            </div>
        </section>
        
        <section id="skills-section" className="skills-section section text-center">
              <h2 className="section-title">Skill Levels</h2>
              <div className="top-skills">
                  <h3 className="subtitle">Top Skills</h3>
                  <div className="row">
                      <div className="item col-xs-12 col-sm-4">
                          <div className="item-inner">
                              <div className="chart-easy-pie text-center">
                                <PieChart data={[ { title: '', value: 95, color: '#00BCD4' }, ]} totalValue={100} lineWidth={10} background="#e8e8e8" startAngle={-90} rounded animate />
                                <span>95%</span>
                              </div>
                              <h4 className="skill-name">Front-end Web Development</h4>
                              <div className="level">Advanced, 8 years</div>
                              <div className="desc">
                                  I've been interested in front-end web development since high school. I strive to stay current on the latest frameworks and practices to produce top-notch web applications.
                              </div>
                          </div>
                      </div>
                      <div className="item col-xs-12 col-sm-4">
                          <div className="item-inner">
                              <div className="chart-easy-pie text-center">
                                <PieChart data={[ { title: '', value: 85, color: '#00BCD4' }, ]} totalValue={100} lineWidth={10} background="#e8e8e8" startAngle={-90} rounded animate />
                                <span>85%</span>
                              </div>
                              <h4 className="skill-name">ASP.NET</h4>
                              <div className="level">Proficient, 5 years</div>
                              <div className="desc">
                                  Over the last five years I have worked on all types of .NET projects including legacy and .NET Core web applications. I have implemented Razor, React, and hybrid front-end solutions
                                  in addition to entity code-first and database-first solutions.
                              </div>
                          </div>
                      </div>
                      <div className="item col-xs-12 col-sm-4">
                          <div className="item-inner">
                              <div className="chart-easy-pie text-center">
                                <PieChart data={[ { title: '', value: 80, color: '#00BCD4' }, ]} totalValue={100} lineWidth={10} background="#e8e8e8" startAngle={-90} rounded animate />
                                <span>80%</span>
                              </div>
                              <h4 className="skill-name">Android Development </h4>
                              <div className="level">Proficient, 2 years</div>
                              <div className="desc">
                                  During my time at Auburn I developed an interest for mobile development. After taking the Android course I was hooked, and I haven't stopped keeping up with it since.
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="other-skills">
                  <h3 className="subtitle">Other Skills</h3>
                  <div className="misc-skills">
                      {languages.map((item, index) => 
                        <>
                        <span className="skill-tag">{item}</span>
                        {(index + 1) % 5 === 0 && <br />}
                        </>
                      )}
                  </div>
              </div>

          </section>

          <section id="contact-section" className="contact-section section">
            <h2 className="section-title">Reach out</h2>
            <div className="intro">
                <img className="profile-image" src="img/chandler-avatar.png" alt="" />
                <div className="dialog">
                    <p>I love discussing new projects and ideas. Building a product from the ground up is extremely rewarding, and I am always excited to be a part of that process. Feel free to reach out through any channel to start a conversation!</p>
                    <p><strong>I can help with the following:</strong></p>
                    <ul className="list-unstyled service-list">
                        <li><FontAwesomeIcon icon={faCheck} /> Full stack web development </li>
                        <li><FontAwesomeIcon icon={faCheck} /> App development for Android and iOS</li>
                        <li><FontAwesomeIcon icon={faCheck} /> UI development</li>
                        <li><FontAwesomeIcon icon={faCheck} /> UX prototyping</li>
                    </ul>
                    <p>Feel free to contact me at <a href="mailto:hello@chandlerreynolds.com">hello@chandlerreynolds.com</a></p>
                    <ul className="social list-inline">
                      <li><a href="https://www.linkedin.com/in/chandler-reynolds-460a9871"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                    </ul>
                </div>
            </div>

          </section>
      </div>

        <footer className="footer text-center">
          <div className="container">
              <small className="copyright"><FontAwesomeIcon icon={faCopyright} /> Chandler Reynolds {new Date().getFullYear()}</small>
          </div>
        </footer>
    </div>
  );
}

export default App;
